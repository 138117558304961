import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import { AuthPage } from './components/index.js'
import reportWebVitals from './reportWebVitals'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <AuthPage />
  </React.StrictMode>
)

reportWebVitals(console.log)
