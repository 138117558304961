import React, { useState } from 'react'
import styles from './register-form.module.css'
import { FaUser, FaLock, FaEye, FaEyeSlash, FaEnvelope } from 'react-icons/fa'

const RegisterForm = ({ onLoginClick }) => {
  const [cpf, setCpf] = useState('')
  const [showPassword, setShowPassword] = useState(false)
  const [showPasswordCheck, setShowPasswordCheck] = useState(false)

  const formatCPF = (value) => {
    const cleaned = value.replace(/\D/g, '')
    const formats = [
      { len: 3, fmt: '$1' },
      { len: 6, fmt: '$1.$2' },
      { len: 9, fmt: '$1.$2.$3' },
      { len: Infinity, fmt: '$1.$2.$3-$4' }
    ]
    const match = formats.find(({ len }) => cleaned.length <= len)
    return cleaned.replace(/(\d{3})(\d{0,3})?(\d{0,3})?(\d+)?/, match.fmt)
  }

  const handleCpfChange = (e) => setCpf(formatCPF(e.target.value))
  const togglePasswordVisibility = () => setShowPassword((prev) => !prev)
  const togglePasswordCheckVisibility = () => setShowPasswordCheck((prev) => !prev)

  return (
    <div id="register-form">
      <div className={styles['wrapper']}>
        <form className={styles['form']}>
          <h1>Registrar</h1>
          <div className={styles['input-box']}>
            <input
              type="text"
              id="cpf"
              name="cpf"
              placeholder="CPF"
              maxLength="14"
              value={cpf}
              onChange={handleCpfChange}
              required
              autoComplete="off"
            />
            <FaUser className={styles['icon']} />
          </div>

          <div className={styles['input-box']}>
            <input
              type="email"
              id="email"
              name="email"
              placeholder="Email"
              autoComplete="off"
              required
            />
            <FaEnvelope className={styles['icon']} />
          </div>

          <div className={styles['input-box']}>
            <input
              type={showPassword ? 'text' : 'password'}
              id="password"
              name="password"
              placeholder="Senha"
              autoComplete="off"
              required
            />

            <div onClick={togglePasswordVisibility}>
              {showPassword ? (
                <FaEye className={styles['eye-toggle-on']} />
              ) : (
                <FaEyeSlash className={styles['eye-toggle-off']} />
              )}
            </div>
            <FaLock className={styles['icon']} />
          </div>

          <div className={styles['input-box']}>
            <input
              type={showPasswordCheck ? 'text' : 'password'}
              id="password"
              name="password"
              placeholder="Confirme sua senha"
              autoComplete="off"
              required
            />
            <div onClick={togglePasswordCheckVisibility}>
              {showPasswordCheck ? (
                <FaEye className={styles['eye-toggle-on']} />
              ) : (
                <FaEyeSlash className={styles['eye-toggle-off']} />
              )}
            </div>
            <FaLock className={styles['icon']} />
          </div>

          <button type="submit">Registrar</button>

          <div className={styles['login-link']}>
            <p>Já tem uma conta? </p>
            <div className={styles['toggle-form']} onClick={onLoginClick}>
              Clique aqui
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}

export default RegisterForm
