import React, { useState } from 'react'
import styles from './login-form.module.css'
import { FaUser, FaLock, FaEye, FaEyeSlash } from 'react-icons/fa'

const LoginForm = ({ onForgotClick, onRegisterClick }) => {
  const [cpf, setCpf] = useState('')
  const [showPassword, setShowPassword] = useState(false)

  const formatCPF = (value) => {
    const cleaned = value.replace(/\D/g, '')
    const formats = [
      { len: 3, fmt: '$1' },
      { len: 6, fmt: '$1.$2' },
      { len: 9, fmt: '$1.$2.$3' },
      { len: Infinity, fmt: '$1.$2.$3-$4' }
    ]
    const match = formats.find(({ len }) => cleaned.length <= len)
    return cleaned.replace(/(\d{3})(\d{0,3})?(\d{0,3})?(\d+)?/, match.fmt)
  }

  const handleCpfChange = (e) => setCpf(formatCPF(e.target.value))
  const togglePasswordVisibility = () => setShowPassword((prev) => !prev)

  return (
    <div id="login-form">
      <div className={styles['wrapper']}>
        <form className={styles['form']}>
          <h1>Entrar</h1>

          <div className={styles['input-box']}>
            <input
              type="text"
              id="cpf"
              name="cpf"
              placeholder="CPF"
              maxLength="14"
              value={cpf}
              onChange={handleCpfChange}
              autoComplete="off"
              required
            />
            <FaUser className={styles['icon']} />
          </div>

          <div className={styles['input-box']}>
            <input
              type={showPassword ? 'text' : 'password'}
              id="password"
              name="password"
              placeholder="Senha"
              autoComplete="off"
              required
            />
            <div onClick={togglePasswordVisibility}>
              {showPassword ? (
                <FaEye className={styles['eye-toggle-on']} />
              ) : (
                <FaEyeSlash className={styles['eye-toggle-off']} />
              )}
            </div>
            <FaLock className={styles['icon']} />
          </div>

          <div className={styles['remember-forgot']}>
            <label>
              <input type="checkbox" name="remember" />
              Lembre de mim
            </label>
            <div className={styles['toggle-form']} onClick={onForgotClick}>
              Esqueceu sua senha?
            </div>
          </div>

          <button className={styles['submit']} type="submit">
            Entrar
          </button>

          <div className={styles['signup-link']}>
            <p>Não tem uma conta? </p>
            <div className={styles['toggle-form']} onClick={onRegisterClick}>
              Clique aqui
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}

export default LoginForm
