import React, { useState } from 'react'
import styles from './forgot-form.module.css'
import { FaUser } from 'react-icons/fa'

const ForgotForm = ({ onLoginClick }) => {
  const [cpf, setCpf] = useState('')

  const formatCPF = (value) => {
    const cleaned = value.replace(/\D/g, '')
    const formats = [
      { len: 3, fmt: '$1' },
      { len: 6, fmt: '$1.$2' },
      { len: 9, fmt: '$1.$2.$3' },
      { len: Infinity, fmt: '$1.$2.$3-$4' }
    ]
    const match = formats.find(({ len }) => cleaned.length <= len)
    return cleaned.replace(/(\d{3})(\d{0,3})?(\d{0,3})?(\d+)?/, match.fmt)
  }

  const handleCpfChange = (e) => setCpf(formatCPF(e.target.value))

  return (
    <div id="forgot-form">
      <div className={styles['wrapper']}>
        <form className={styles['form']}>
          <h1>Esquecimento</h1>

          <div className={styles['input-box']}>
            <input
              type="text"
              id="cpf"
              name="cpf"
              placeholder="CPF"
              maxLength="14"
              value={cpf}
              onChange={handleCpfChange}
              autoComplete="off"
              required
            />
            <FaUser className={styles['icon']} />
          </div>

          <button className={styles['submit']} type="submit">
            Enviar
          </button>

          <div className={styles['small-text']}>
            <p>Não se preocupe eu também vivo esquecendo minhas senhas </p>
          </div>

          <div className={styles['login-link']}>
            <p>Lembrou a sua senha? </p>
            <div className={styles['toggle-form']} onClick={onLoginClick}>
              Clique aqui
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}

export default ForgotForm
