import React, { useState } from 'react'
import './auth-page.css'
import LoginForm from './login-form/login-form.jsx'
import RegisterForm from './register-form/register-form.jsx'
import ForgotForm from './forgot-form/forgot-form.jsx'

const AuthPage = () => {
  const [isLogin, setShowLogin] = useState(true)
  const [isRegister, setShowRegister] = useState(false)
  const [isForgot, setShowForgot] = useState(false)
  const [animationClass, setAnimationClass] = useState('')

  const registerToLogin = () => {
    setAnimationClass('slide-out-right')
    setTimeout(() => {
      setAnimationClass('position-left')
    }, 600)
    setTimeout(() => {
      setShowRegister(false)
      setShowForgot(false)
      setShowLogin(true)
      setAnimationClass('slide-in')
    }, 700)
  }

  const loginToRegister = () => {
    setAnimationClass('slide-out-left')
    setTimeout(() => {
      setAnimationClass('position-right')
    }, 600)
    setTimeout(() => {
      setShowLogin(false)
      setShowForgot(false)
      setShowRegister(true)
      setAnimationClass('slide-in')
    }, 700)
  }

  const loginToForgot = () => {
    setAnimationClass('slide-out-left')
    setTimeout(() => {
      setAnimationClass('position-up')
    }, 600)
    setTimeout(() => {
      setShowLogin(false)
      setShowRegister(false)
      setShowForgot(true)
      setAnimationClass('slide-in')
    }, 700)
  }

  const forgotToLogin = () => {
    setAnimationClass('slide-out-up')
    setTimeout(() => {
      setAnimationClass('position-left')
    }, 600)
    setTimeout(() => {
      setShowRegister(false)
      setShowForgot(false)
      setShowLogin(true)
      setAnimationClass('slide-in')
    }, 700)
  }

  const renderForm = () => {
    if (isLogin) {
      return <LoginForm onRegisterClick={loginToRegister} onForgotClick={loginToForgot} />
    }
    if (isRegister) {
      return <RegisterForm onLoginClick={registerToLogin} />
    }
    if (isForgot) {
      return <ForgotForm onLoginClick={forgotToLogin} />
    }
    return <LoginForm onRegisterClick={loginToRegister} onForgotClick={loginToForgot} />
  }

  return (
    <div id="auth-page">
      <div className={`form-container ${animationClass}`}>{renderForm()}</div>
    </div>
  )
}

export default AuthPage
